import React, { useEffect, useRef } from "react";

export const Starfield: React.FC<{ starCount: number }> = ({ starCount }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const updateCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    updateCanvasSize();

    const stars: { x: number; y: number; z: number; speed: number }[] = [];

    const initializeStars = () => {
      stars.length = 0; // Clear existing stars
      for (let i = 0; i < starCount; i++) {
        stars.push({
          x: Math.random() * canvas.width - canvas.width / 2,
          y: Math.random() * canvas.height - canvas.height / 2,
          z: Math.random() * canvas.width,
          speed: Math.random() * 2 + 1, // Speed between 1 and 3
        });
      }
    };

    initializeStars();

    const animate = () => {
      ctx.fillStyle = "#121212";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "rgba(255, 255, 255, 0.7)";
      stars.forEach((star) => {
        star.z -= star.speed;

        if (star.z <= 0) {
          star.x = Math.random() * canvas.width - canvas.width / 2;
          star.y = Math.random() * canvas.height - canvas.height / 2;
          star.z = canvas.width;
        }

        const x = star.x * (canvas.width / star.z) + canvas.width / 2;
        const y = star.y * (canvas.width / star.z) + canvas.height / 2;
        const s = Math.max(0, (1 - star.z / canvas.width) * 2);

        if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
          ctx.beginPath();
          ctx.arc(x, y, s, 0, 2 * Math.PI);
          ctx.fill();
        }
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      updateCanvasSize();
      initializeStars(); // Reinitialize stars on resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [starCount]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};
