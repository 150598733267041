import React, { useState, useRef } from "react";
import {
  Camera,
  Bot,
  CircuitBoard,
  Database,
  Share2,
  Lock,
  RefreshCw,
  Rocket,
  ChevronDown,
} from "lucide-react";
import { Starfield } from "../components/Starfield";
import { Navbar } from "../components/Navbar";
import { LoginModal } from "../components/LoginModal";
import { Button } from "../components/Button";
import "../App.css";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const industryRef = useRef<HTMLDivElement>(null);

  const handleOpenLoginModal = () => setIsLoginModalOpen(true);
  const handleCloseLoginModal = () => setIsLoginModalOpen(false);

  const sectionStyle: React.CSSProperties = {
    minHeight: `calc(100vh - 75px)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 32px",
    boxSizing: "border-box",
    position: "relative",
    marginTop: `75px`, // Add top margin to account for fixed navbar
  };

  const contentStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
  };

  const textColumnStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "800px",
  };

  const headingStyle: React.CSSProperties = {
    fontSize: "clamp(2.5rem, 6vw, 4rem)",
    fontWeight: 700,
    marginBottom: "24px",
    color: "#6EE7B7",
    lineHeight: 1.1,
    textAlign: "left",
  };

  const paragraphStyle: React.CSSProperties = {
    fontSize: "1.5rem",
    marginBottom: "32px",
    lineHeight: 1.75,
    fontWeight: 700,
    color: "#e2e8f0",
    textAlign: "left",
  };

  const pillContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    flexWrap: "wrap",
    width: "100%",
    padding: "16px 32px",
    backdropFilter: "blur(10px)",
    marginBottom: "60px",
  };

  const pillStyle: React.CSSProperties = {
    padding: "8px 16px",
    borderRadius: "20px",
    backgroundColor: "rgba(110, 231, 183, 0.1)",
    color: "#6EE7B7",
    fontSize: "0.875rem",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const activePillStyle: React.CSSProperties = {
    ...pillStyle,
    backgroundColor: "rgba(110, 231, 183, 0.3)",
  };

  const industryContentStyle: React.CSSProperties = {
    display: "flex",
    width: "100%",
    maxWidth: "1200px",
    gap: "40px",
    alignItems: "center",
  };

  const industryTextStyle: React.CSSProperties = {
    flex: 1,
    textAlign: "left",
  };

  const industryImageStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const screenshotPlaceholderStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "500px",
    height: "300px",
    backgroundColor: "rgba(110, 231, 183, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    color: "#6EE7B7",
    fontSize: "1.5rem",
  };

  const scrollCueStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    animation: "bounce 2s infinite",
  };

  const handlePillClick = (industry: string) => {
    setSelectedIndustry(industry);
  };

  const handleScrollToIndustry = () => {
    industryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const industries = [
    {
      name: "Robotics",
      title: "Data Observability in Robotics",
      description:
        "Enhance robotics performance with real-time monitoring of sensors, actuators, and control systems. Our platform enables predictive maintenance, performance optimization, and improved safety protocols through data-driven insights and anomaly detection.",
    },
    {
      name: "IoT",
      title: "Data Observability for IoT",
      description:
        "Gain comprehensive visibility into vast networks of connected devices. Our IoT solution offers scalable monitoring for millions of devices, edge computing integration, security compliance monitoring, and cross-device data correlation for system-wide insights.",
    },
    {
      name: "Automation",
      title: "Data Observability in Automation",
      description:
        "Ensure smooth operations and quick problem resolution in automation systems. Our platform provides end-to-end process visibility, automated root cause analysis, integration with existing SCADA and MES systems, and AI-driven process optimization recommendations.",
    },
    {
      name: "Aerospace",
      title: "Data Observability for Aerospace",
      description:
        "Ensure safety, compliance, and operational efficiency in aerospace applications. Our solution offers real-time monitoring of aircraft systems, predictive maintenance for reduced ground time, compliance tracking, and flight data analysis for performance optimization.",
    },
  ];

  const renderIndustryContent = () => {
    if (!selectedIndustry) {
      return (
        <div style={textColumnStyle}>
          <h2 style={headingStyle}>Explore Our Solutions</h2>
          <p style={paragraphStyle}>
            Select an industry above to learn more about our data observability
            solutions.
          </p>
        </div>
      );
    }

    const industry = industries.find((ind) => ind.name === selectedIndustry);
    if (!industry) return null;

    return (
      <div style={industryContentStyle}>
        <div style={industryTextStyle}>
          <h2 style={headingStyle}>{industry.title}</h2>
          <p style={paragraphStyle}>{industry.description}</p>
        </div>
        <div style={industryImageStyle}>
          <div style={screenshotPlaceholderStyle}>
            Product Screenshot for {industry.name}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#0f172a",
        color: "#e2e8f0",
        overflowX: "hidden",
        fontFamily: "sans-serif",
      }}
    >
      <Starfield starCount={200} />
      <div
        style={{
          position: "relative",
          zIndex: 3,
          backgroundColor: "rgba(18, 18, 18, 0.6)",
        }}
      >
        <Navbar onLogin={handleOpenLoginModal} />

        {/* Hero Section */}
        <section style={sectionStyle}>
          <div style={contentStyle}>
            <div style={textColumnStyle}>
              <h1 style={headingStyle}>Build Hardware Intelligence</h1>
              <p style={paragraphStyle}>
                Unify sensor data, camera feeds, and robot actions in one
                powerful platform
              </p>
              {/*<Button onClick={handleScrollToIndustry}>*/}
              {/*  Explore Solutions*/}
              {/*</Button>*/}
            </div>
          </div>
          {/*<div style={scrollCueStyle} onClick={handleScrollToIndustry}>*/}
          {/*  <ChevronDown size={32} color="#6EE7B7" />*/}
          {/*</div>*/}
        </section>

        {/* Industry Section */}
        {/*<section*/}
        {/*  ref={industryRef}*/}
        {/*  style={{*/}
        {/*    ...sectionStyle,*/}
        {/*    justifyContent: "flex-start",*/}
        {/*    marginTop: 0, // Remove top margin for this section*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div style={pillContainerStyle}>*/}
        {/*    {industries.map((industry) => (*/}
        {/*      <div*/}
        {/*        key={industry.name}*/}
        {/*        style={*/}
        {/*          selectedIndustry === industry.name*/}
        {/*            ? activePillStyle*/}
        {/*            : pillStyle*/}
        {/*        }*/}
        {/*        onClick={() => handlePillClick(industry.name)}*/}
        {/*        onMouseEnter={(e) => {*/}
        {/*          if (selectedIndustry !== industry.name) {*/}
        {/*            e.currentTarget.style.backgroundColor =*/}
        {/*              "rgba(110, 231, 183, 0.2)";*/}
        {/*          }*/}
        {/*        }}*/}
        {/*        onMouseLeave={(e) => {*/}
        {/*          if (selectedIndustry !== industry.name) {*/}
        {/*            e.currentTarget.style.backgroundColor =*/}
        {/*              "rgba(110, 231, 183, 0.1)";*/}
        {/*          }*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {industry.name}*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  <div style={contentStyle}>{renderIndustryContent()}</div>*/}
        {/*</section>*/}
      </div>
      <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
    </div>
  );
};

export default Home;
