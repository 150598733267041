import React from "react";
import "./App.css";
import { Amplify } from "aws-amplify";
import { AuthProvider } from "./contexts/AuthContext";
import AppRoutes from "./routes";

// Declare types for environment variables
declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_USER_POOL_ID: string;
      REACT_APP_USER_POOL_CLIENT_ID: string;
    }
  }
}

// Use environment variables directly, with fallback values
const userPoolId = process.env.REACT_APP_USER_POOL_ID || "dummy-id";
const userPoolClientId =
  process.env.REACT_APP_USER_POOL_CLIENT_ID || "dummy-client-id";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId,
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <AppRoutes />
      </div>
    </AuthProvider>
  );
}

export default App;
