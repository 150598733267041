import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedComponents";
import Dashboard from "./pages/Dashboard";

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        {/*<Route path="/live" element={<Live />} />*/}
        {/*<Route path="/profile" element={<Profile />} />*/}
        {/*<Route path="/dashboard/:cameraId/:timestamp" element={<CameraView />} />*/}
        {/*/!* Add other protected routes here if needed *!/*/}
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
