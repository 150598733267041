import React from "react";
import { LucideIcon } from "lucide-react";
interface NavItemProps {
  icon: LucideIcon;
  onClick: () => void;
  isActive: boolean;
  tooltip: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  icon: Icon,
  onClick,
  isActive,
  tooltip,
}) => (
  <div style={{ position: "relative" }}>
    <button
      onClick={onClick}
      style={{
        padding: "12px",
        width: "100%",
        color: isActive ? "#6EE7B7" : "#A0AEC0",
        backgroundColor: isActive ? "rgba(110, 231, 183, 0.1)" : "transparent",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s, color 0.3s",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "rgba(110, 231, 183, 0.1)";
        e.currentTarget.style.color = "#6EE7B7";
      }}
      onMouseLeave={(e) => {
        if (!isActive) {
          e.currentTarget.style.backgroundColor = "transparent";
          e.currentTarget.style.color = "#A0AEC0";
        }
      }}
    >
      <Icon style={{ width: "24px", height: "24px" }} />
    </button>
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#4A5568",
        color: "#FFFFFF",
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "14px",
        whiteSpace: "nowrap",
        visibility: "hidden",
        opacity: 0,
        transition: "opacity 0.3s",
      }}
    >
      {tooltip}
    </div>
  </div>
);
