import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, signOut, getCurrentUser } from "aws-amplify/auth";
import { Button } from "./Button";
import { X, AlertCircle, Mail, Lock, ArrowRight } from "lucide-react";
import { useAuth } from "../contexts/AuthContext";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type FormState = "login" | "forgotPassword" | "waitlist";

export const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const [formState, setFormState] = useState<FormState>("login");
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const { checkAuth } = useAuth();

  useEffect(() => {
    checkCurrentUser();
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const checkCurrentUser = async () => {
    try {
      const user = await getCurrentUser();
      setCurrentUser(user);
    } catch (error) {
      setCurrentUser(null);
    }
  };

  const handleGoToDashboard = async () => {
    setIsLoading(true);
    await checkAuth();
    try {
      navigate("/dashboard");
      onClose();
    } catch (error) {
      console.error("Error going to dashboard", error);
      setIsLoading(false);
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const signInResult = await signIn({ username: email, password });
      if (signInResult.isSignedIn) {
        await checkAuth();
        navigate("/dashboard");
      } else {
        setError("Additional authentication steps may be required.");
      }
    } catch (error: any) {
      console.error("Sign-in error:", error);
      setError(getErrorMessage(error.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    // Implement forgot password logic here
    setIsLoading(false);
  };

  const handleWaitlistSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    // Implement waitlist signup logic here
    setIsLoading(false);
  };

  const getErrorMessage = (errorCode: string): string => {
    switch (errorCode) {
      case "UserNotFoundException":
        return "No account found with this email address.";
      case "NotAuthorizedException":
        return "Incorrect email or password.";
      case "UserNotConfirmedException":
        return "Please confirm your account before logging in.";
      default:
        return "An error occurred during sign-in. Please try again.";
    }
  };

  if (!isOpen) return null;

  const inputContainerStyle: React.CSSProperties = {
    position: "relative",
    marginBottom: "10px",
  };

  const inputStyle: React.CSSProperties = {
    width: "100%",
    padding: "12px 16px 12px 16px",
    backgroundColor: "#2C2C2C",
    color: "#FFFFFF",
    border: "1px solid #3D3D3D",
    borderRadius: "6px",
    outline: "none",
    fontSize: "14px",
    transition: "border-color 0.3s ease",
  };

  const labelStyle: React.CSSProperties = {
    display: "block",
    marginBottom: "8px",
    color: "#B0B0B0",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "left",
  };

  const renderForm = () => {
    switch (formState) {
      case "login":
        return (
          <form onSubmit={handleLogin}>
            <div style={inputContainerStyle}>
              <label htmlFor="email" style={labelStyle}>
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={inputStyle}
                placeholder="Enter your email"
                required
              />
            </div>
            <div style={inputContainerStyle}>
              <label htmlFor="password" style={labelStyle}>
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={inputStyle}
                placeholder="Enter your password"
                required
              />
            </div>
            <label style={labelStyle}>
              <button
                type="button"
                onClick={() => setFormState("forgotPassword")}
                style={{
                  background: "none",
                  border: "none",
                  color: "#6EE7B7",
                  cursor: "pointer",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Forgot Password?
              </button>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Button type="submit" variant="primary" disabled={isLoading}>
                {isLoading ? "Signing In..." : "Login"}
              </Button>
            </div>
          </form>
        );
      case "forgotPassword":
        return (
          <form onSubmit={handleForgotPassword}>
            <div style={inputContainerStyle}>
              <label htmlFor="resetEmail" style={labelStyle}>
                Email
              </label>
              <input
                id="resetEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={inputStyle}
                placeholder="Enter your email"
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Button type="submit" variant="primary" disabled={isLoading}>
                {isLoading ? "Sending..." : "Reset Password"}
              </Button>
              <button
                type="button"
                onClick={() => setFormState("login")}
                style={{
                  background: "none",
                  border: "none",
                  color: "#6EE7B7",
                  cursor: "pointer",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Back to Login
              </button>
            </div>
          </form>
        );
      case "waitlist":
        return (
          <form onSubmit={handleWaitlistSignup}>
            <div style={inputContainerStyle}>
              <label htmlFor="waitlistEmail" style={labelStyle}>
                Email
              </label>
              <input
                id="waitlistEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={inputStyle}
                placeholder="Enter your email"
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Button type="submit" variant="primary" disabled={isLoading}>
                {isLoading ? "Joining..." : "Join Waitlist"}
              </Button>
              <button
                type="button"
                onClick={() => setFormState("login")}
                style={{
                  background: "none",
                  border: "none",
                  color: "#6EE7B7",
                  cursor: "pointer",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Back to Login
              </button>
            </div>
          </form>
        );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(18, 18, 18, 0.8)",
        zIndex: 1000,
      }}
    >
      <div
        ref={modalRef}
        style={{
          backgroundColor: "#1E1E1E",
          padding: "40px",
          borderRadius: "12px",
          width: "100%",
          maxWidth: "400px",
          color: "#FFFFFF",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            background: "none",
            border: "none",
            cursor: "pointer",
            color: "#B0B0B0",
          }}
        >
          <X size={24} />
        </button>
        <h2
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "24px",
            color: "#6EE7B7",
          }}
        >
          {currentUser
            ? "Welcome Back"
            : formState === "login"
              ? "Login"
              : formState === "forgotPassword"
                ? "Reset Password"
                : "Join Waitlist"}
        </h2>
        {currentUser ? (
          <div>
            <p
              style={{
                marginBottom: "20px",
                color: "#B0B0B0",
                fontSize: "16px",
              }}
            >
              You are already signed in as{" "}
              <strong style={{ color: "#FFFFFF" }}>
                {currentUser.username}
              </strong>
              .
            </p>
            <Button
              onClick={handleGoToDashboard}
              variant="primary"
              disabled={isLoading}
              style={{ width: "100%", paddingRight: "36px" }}
            >
              {isLoading ? "Going to Dashboard..." : "Go to Dashboard"}
            </Button>
          </div>
        ) : (
          <>
            {renderForm()}
            {error && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#EF5350",
                  marginBottom: "16px",
                  fontSize: "14px",
                }}
              >
                <AlertCircle size={18} style={{ marginRight: "8px" }} />
                <span>{error}</span>
              </div>
            )}
            {formState === "login" && (
              <div style={{ textAlign: "center", marginTop: "24px" }}>
                <p
                  style={{
                    color: "#B0B0B0",
                    marginBottom: "12px",
                    fontSize: "14px",
                  }}
                >
                  Don't have an account?
                </p>
                <Button
                  variant="secondary"
                  onClick={() => setFormState("waitlist")}
                  style={{ width: "100%", paddingRight: "36px" }}
                >
                  Sign up for Waitlist
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
