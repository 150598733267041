import { Button } from "./Button";

export const Navbar: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 32px",
        backgroundColor: "rgba(18, 18, 18, 0.8)", // Semi-transparent background
        borderBottom: "1px solid rgba(30, 30, 30, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backdropFilter: "blur(5px)", // Adds a blur effect to the background
      }}
    >
      <div style={{ fontSize: "24px", fontWeight: "bold", color: "#6EE7B7" }}>
        Framerate
      </div>
      <Button onClick={onLogin} variant="secondary">
        Login
      </Button>
    </nav>
  );
};
