import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useAuth } from "../contexts/AuthContext";
import {
  Home as HomeIcon,
  Bot,
  Camera,
  CircuitBoard,
  LogOut,
  User,
    Plus,
    Sparkles,
    Database
} from "lucide-react";
import { NavItem } from "../components/NavItem";
import SystemCard, {SystemData} from "../components/Card";
import "./Dashboard.scss";

const systemData: SystemData = {
  id: "001",
  name: "Warehouse Robot A1",
  status: "online",
  lastSynced: "2023-05-20 15:30:00"
};

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("home");
  const { checkAuth } = useAuth();
  const [systems, setSystems] = useState<SystemData[]>([systemData]);
  const [newSystemName, setNewSystemName] = useState("");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      await checkAuth();
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const addSystem = () => {
    if (newSystemName.trim() !== "") {
      const newSystem: SystemData = {
        id: Date.now().toString(),
        name: newSystemName,
        status: "offline",
        lastSynced: new Date().toISOString(),
      };
      setSystems([...systems, newSystem]);
      setNewSystemName("");
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>Your Systems</h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Plus className="plusStyle" size={28} />
                </div>
              </div>
              <div>
                {systems.map((system) => (
                    <SystemCard key={system.id} system={system} />
                ))}
              </div>
            </div>
        );
      case "analyze":
        return (
            <h2 style={{fontSize: "24px", fontWeight: "bold"}}>Analyze and Train</h2>
        );
      case "data":
        return (
            <h2 style={{fontSize: "24px", fontWeight: "bold"}}>Data</h2>
        );
      case "profile":
        return (
            <h2 style={{fontSize: "24px", fontWeight: "bold"}}>Profile</h2>
        );
      default:
        return null;
    }
  };

  return (
      <div
          style={{ display: "flex", height: "100vh", backgroundColor: "#F7FAFC" }}
    >
      <nav
        style={{
          backgroundColor: "rgba(18, 18, 18)",
          width: "50px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <NavItem
            icon={HomeIcon}
            onClick={() => setActiveSection("home")}
            isActive={activeSection === "home"}
            tooltip="Home"
          />
          <NavItem
            icon={Sparkles}
            onClick={() => setActiveSection("analyze")}
            isActive={activeSection === "analyze"}
            tooltip="Analyze"
          />
          <NavItem
              icon={Database}
              onClick={() => setActiveSection("data")}
              isActive={activeSection === "data"}
              tooltip="Data"
          />
        </div>
        <div>
          <NavItem
            icon={User}
            onClick={() => {
              setActiveSection("profile")
            }}
            isActive={false}
            tooltip="Profile"
          />
          <NavItem
            icon={LogOut}
            onClick={handleLogout}
            isActive={false}
            tooltip="Logout"
          />
        </div>
      </nav>

      {/* Main Content Area */}
      <main
        style={{
          flex: 1,
          padding: "32px",
          overflowY: "auto",
        }}
      >
        {renderContent()}
      </main>
    </div>
  );
};

export default Dashboard;
