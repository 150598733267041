import React from 'react';
import { Activity } from 'lucide-react';
import "./Card.scss"

export interface SystemData {
    id: string;
    name: string;
    status: 'online' | 'offline' | 'maintenance';
    lastSynced: string;
}

interface SystemCardProps {
    system: SystemData;
}

const SystemCard: React.FC<SystemCardProps> = ({ system }) => {
    const cardStyle: React.CSSProperties = {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: '16px',
        width: '300px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#ffffff',
    };

    const headerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const titleStyle: React.CSSProperties = {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
        margin: 0,
    };

    const statusStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        color: system.status === 'online' ? '#4caf50' : system.status === 'offline' ? '#f44336' : '#ff9800',
    };

    const infoStyle: React.CSSProperties = {
        fontSize: '12px',
        color: '#555',
        marginBottom: '8px',
        textAlign: 'left',
    };

    const modelPlaceholderStyle: React.CSSProperties = {
        width: '100%',
        height: '200px',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        marginTop: '12px',
    };

    return (
        <div className="card" style={cardStyle}>
            <div style={headerStyle}>
                <h2 style={titleStyle}>{system.name}</h2>
                <div style={statusStyle}>
                    {system.status}
                </div>
            </div>
            <p style={infoStyle}>Last Synced: {system.lastSynced}</p>
            <div style={modelPlaceholderStyle}>
                Model Placeholder
            </div>
        </div>
    );
};

export default SystemCard;